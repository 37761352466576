body {
  background: #ffffff;
  font-family: "Montserrat-Regular";
  overflow-x: hidden;
}

h2 {
  font-size: 1.5rem;
  font-size: 800;
  font-family: "Montserrat-Bold";
}

nav.navbar-container {
  display: flex;
  width: 100%;
  height: 100px;

  align-items: center;
  padding: 0 300px;
  border-bottom: 1px solid rgb(221, 221, 221);
  background: #ffffff;
  position: fixed;
  z-index: 999;
  gap: 2rem;
}

nav.navbar-container img {
  width: 150px;
}

nav.navbar-container ul {
  width: 100%;
}

nav.navbar-container ul li {
  display: inline;
  margin-left: 50px;
  cursor: pointer;
  transition: 0.2s ease-in;
}

nav.navbar-container ul li a:hover {
  color: var(--purple-color);
}

main.container-landing-page {
  width: 100%;
  height: auto;
  padding: 100px 300px;
}

article.container-welcome {
  width: 100%;
  height: auto;
}

div.description-welcome {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: center;
}

div.description-welcome div.principal-description {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.5rem;
  padding-top: 50px;
  line-height: 2rem;
}

div.description-welcome div.principal-description span {
  line-height: 2rem;
}

div.description-welcome div.principal-description .container-benefits {
  display: grid;
  width: 100%;
  margin-top: 10px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1rem;
}

button.button-start {
  width: 50%;
  height: 50px;
  border-radius: 50px;
  border: none;
  background: var(--purple-color);
  color: #ffffff;
  cursor: pointer;
  font-family: "Montserrat-Regular";
}

div.description-welcome
  div.principal-description
  .container-benefits
  .benefits-square {
  display: flex;
  height: 110px;
  border-radius: 20px;
  background: rgba(253, 249, 245, 1);
  font-family: "Montserrat-Bold";
  justify-content: center;
  align-items: center;
}

.benefits-square .item{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


article.container-steps {
  width: 100%;
  height: auto;
  min-height: 300px;
  padding-top: 20px;
}

article.container-steps img {
  width: 250px;
}

article.container-steps div.informations-of-step {
  display: flex;
  margin-top: 10px;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}

article.container-steps div.informations-of-step div.qrcode-whatsapp {
  text-align: center;
}

article.container-steps div.informations-of-step div.qrcode-whatsapp img {
  width: 150px;
}

article.container-steps div.informations-of-step div.steps {
  width: 100%;
  display: flex;
}

article.container-steps div.informations-of-step div.steps .arrow-step {
  margin-top: 20px;
}

article.container-steps div.informations-of-step div.steps div.step {
  position: relative;
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  text-align: center;
}

article.container-steps
  div.informations-of-step
  div.steps
  div.step
  div.icon-step {
  width: 100px;
  height: 100px;
  background: rgba(241, 211, 169, 1);
  border-radius: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

article.container-steps
  div.informations-of-step
  div.steps
  div.step
  div.icon-step
  svg {
  width: 300px;
  transform: scale(2);
}

article.container-offers {
  width: 100%;
  height: auto;
  min-height: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* gap: 5rem; */
}

article.container-offers div.description-container {
  width: 80%;
  min-height: 200px;
  height: auto;
  background: rgba(241, 211, 169, 1);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 25px 50px;
}

article.container-offers div.description-container span {
  line-height: 2rem;
}

article.container-offers div.description-container b {
  font-family: "Montserrat-Bold";
}

article.faq-container {
  width: 100%;
  min-height: 300px;
  height: auto;
  background: rgb(253, 249, 245);
  padding: 70px 300px;
}

article.faq-container div.faq-content {
  width: 100%;
  height: 100%;
  min-height: 200px;
  margin-top: 10px;
}

article.faq-container div.faq-content .title-faq {
  font-size: 1rem;
}

article.faq-container div.faq-content .faq-item {
  display: block;
  height: auto;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(194, 194, 194, 0.315);
  cursor: pointer;
}

article.faq-container div.faq-content .faq-item .question {
  display: flex;
  justify-content: space-between;
}

article.faq-container div.faq-content .answer {
  display: none;
}

article.faq-container div.faq-content .text-responses {
  line-height: 1.5rem;
}
article.faq-container div.faq-content .answer.open {
  display: block;
  margin-top: 10px;
}

.float-whatsapp-icon {
  position: fixed;
  bottom: 50px;
  right: 50px;
}

.float-whatsapp-icon img {
  width: 70px;
  height: 70px;
  border-radius: 50px;
}

.has-question {
  width: 100%;
  height: 300;
  margin-bottom: 30px;
  padding: 70px 250px;
}

.has-question .has-question-content {
  width: 100%;
  height: 100%;
  background: var(--purple-color);
  padding: 50px;
  border-radius: 8px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
}

.has-question .has-question-content button {
  width: 300px;
  height: 50px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  background: #00da5f;
  font-family: "Montserrat-Regular";
  font-size: 1rem;
}

.footer-container {
  display: flex;
  width: 100%;
  min-height: 300px;
  height: auto;
  background: var(--purple-color);
  padding: 30px 300px;
  gap: 1.5rem;
}

.footer-container a, .footer-container span, .footer-container h3 {
  color: #ffffff;
}

.footer-container a {
  cursor: pointer;
}

.footer-container h3 {
  font-size: 1.2rem;
  font-weight: bold;
}

.footer-container .footer-item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}


/* 480px < 768px MOBILE */
/* 834px < 1024px TABLET */
/* 1440px NOTEBOOK */
/* 1440px DESKTOP */

@media screen and (max-width: 1500px) {
  main.container-landing-page {
    padding: 100px 100px;
  }

  article.container-steps div.informations-of-step {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  article.container-steps div.informations-of-step div.steps {
    margin-top: 15px;
  }

  nav.navbar-container {
    padding: 0 100px;
  }
}

@media screen and (max-width: 1200px) {
  div.description-welcome div.principal-description {
    width: 80%;
  }

  div.description-welcome {
    flex-direction: column-reverse;
    align-items: center;
    gap: 1rem;
  }

  article.container-steps
    div.informations-of-step
    div.steps
    div.step
    div.icon-step {
    width: 80px;
    height: 80px;
  }

  article.container-steps
    div.informations-of-step
    div.steps
    div.step
    div.icon-step
    svg {
    transform: scale(1.5);
  }

  article.container-steps
    div.informations-of-step
    div.steps
    div.step
    span.description-step {
    font-size: 0.8rem;
  }

  article.faq-container {
    padding: 70px 100px;
  }

  nav.navbar-container ul li {
    margin-left: 25px;
  }

  .footer-container {
    flex-direction: column;
    justify-content: center;
    padding: 30px 120px;
  }

  .has-question {
    padding: 70px 100px;
  }

  .has-question .has-question-content button {
    width: 200px;
    height: 50px;
  }
}

@media screen and (max-width: 768px) {
  main.container-landing-page {
    padding: 100px 10px;
  }

  div.description-welcome div.principal-description {
    width: 100%;
  }

  article.container-steps
    div.informations-of-step
    div.steps
    div.step
    div.icon-step
    svg {
    transform: scale(2);
  }

  article.container-steps div.informations-of-step {
    flex-direction: column;
    align-items: center;
  }

  article.container-steps div.informations-of-step div.steps {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  article.container-steps div.informations-of-step div.steps svg.arrow-step {
    transform: rotate(90deg) scale(0.8);
  }

  article.container-offers {
    flex-direction: column;
  }

  article.container-offers h2 {
    font-size: 1.2rem;
  }

  article.container-offers div.description-container {
    width: 100%;
    padding: 10px 20px;
  }

  div.description-welcome
    div.principal-description
    .container-benefits
    .benefits-square {
    font-size: 0.7rem;
  }

  article.faq-container {
    padding: 70px 20px;
  }

  nav.navbar-container {
    padding: 0 20px;
  }

  nav.navbar-container img {
    width: 120px;
  }

  nav.navbar-container ul li {
    margin-left: 10px;
    font-size: 0.8rem;
  }

  nav.navbar-container img {
    width: 80px;
  }

  nav.navbar-container {
    gap: 0.5rem;
  }

  .float-whatsapp-icon {
    bottom: 20px;
    right: 20px;
  }

  button.button-start {
    width: 100%;
  }
  .footer-container {
    flex-direction: column;
    justify-content: center;
    padding: 30px 50px;
  }

  .has-question {
    padding: 70px 20px;
    margin-bottom: 10px;
  }
  
  .has-question .has-question-content  {
    flex-direction: column;
    gap: 1rem;
    padding: 30px;
  }

  .has-question .has-question-content button {
    width: 140px;
    height: 50px;
  }

}