:root {
  --background-color: #F3F6F8;
  --purple-color: #840aff;
}

body {
  background: var(--background-color);
	font-family: 'Circular-normal';
}

@font-face {
  font-family: 'Circular-normal';
	font-weight: normal;
  font-style: normal;
  src: url(../public/fonts/Circular/CircularStd-Light.otf);
}

@font-face {
	font-family: 'Circular-medium';
	font-weight: normal;
  font-style: normal;
  src: url(../public/fonts/Circular/CircularStd-Medium.otf);
}

@font-face {
	font-family: 'Circular-bold';
	font-weight: bold;
  font-style: normal;
  src: url(../public/fonts/Circular/CircularStd-Bold.otf);
}

@font-face {
	font-family: 'Montserrat-Regular';
	font-weight: normal;
  font-style: normal;
  src: url(../public/fonts/Montserrat/Montserrat-Regular.ttf);
}

@font-face {
	font-family: 'Montserrat-Bold';
	font-weight: normal;
  font-style: bold;
  src: url(../public/fonts/Montserrat/Montserrat-Bold.ttf);
}
