div.container-home {
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;

  font-family: "Montserrat-Regular";
}

div.container-home button {
  width: 200px;
  height: 40px;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  background: var(--purple-color);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  color: #fff;

  transition: 0.2s ease-in;
  font-family: "Montserrat-Regular";
}

div.container-home button:hover {
  background: #fff;
  color: #000;
}

.float-whatsapp-icon {
  position: absolute;
  bottom: 50px;
  right: 50px;
}

.float-whatsapp-icon img {
  width: 70px;
  height: 70px;
  border-radius: 50px;
}

@media screen and (max-width: 500px) {
  div.container-home span {
		font-size: .8rem;
    text-align: center;
	}
  div.container-home button {
		width: 140px;
	}
	img.logo-amypetz-home {
		width: 150px;
	}

	.float-whatsapp-icon {
		position: absolute;
		bottom: 20px;
		right: 20px;
	}
}
