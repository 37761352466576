nav.nav-logo {
  display: flex;
  width: 100%;
  height: 100px;
  justify-content: space-between;
  padding: 15px 120px;
  align-items: center;
}

nav.nav-logo div.go-back {
  display: flex;
  align-items: center;
  gap: 0.2rem;
  font-family: "Montserrat-Regular";
}

nav.nav-logo img {
  width: 120px;
  height: 70px;
}

div.container-chat {
  width: 100%;
  height: calc(100% - 100px);
  background: #ffff;
  border-radius: 30px 30px 0px 0px;
  padding: 50px 120px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  animation: slideUp 1s ease-in-out forwards;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

div.container-chat div.content-chat {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
}

div.container-chat div.content-chat div.footer-chat {
  display: flex;
  position: absolute;
  width: 100%;
  height: 90px;
  background: #f2f2f2;
  bottom: 0;
  border-radius: 20px;
}


div.container-chat div.content-chat div.footer-chat form {
  width: 100%;
  height: 100%;
  display: flex;
}

div.container-chat div.content-chat div.footer-chat input {
  width: 100%;
  height: 100%;
  padding-left: 15px;
  font-size: normal;
  font-size: 300;
  border: none;
  background: none;
  font-family: "Montserrat-Regular";
}

div.container-chat div.content-chat div.footer-chat input:focus {
  outline: none;
}

div.container-chat div.content-chat div.footer-chat button {
  background: none;
  border: none;
}

div.container-chat div.content-chat div.footer-chat button .icon-send {
  position: absolute;
  transform: rotate(45deg);
  cursor: pointer;
  right: 25px;
  top: 30%;
}

div.container-chat div.content-chat div.conversation-container {
  position: relative;
  width: 100%;
  height: calc(100% - 90px);
  font-family: "Montserrat-Regular";
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-right: 10px;
  overflow: auto;
  scroll-behavior: smooth;
}


div.container-chat div.content-chat div.conversation-container::-webkit-scrollbar {
  width: 6px;
}

div.container-chat div.content-chat div.conversation-container::-webkit-scrollbar-track {
  background: #ffffff;
}

div.container-chat div.content-chat div.conversation-container::-webkit-scrollbar-thumb {
  background-color: var(--purple-color);
  border-radius: 40px;
}

div.container-chat div.content-chat div.conversation-container span.name {
  font-family: "Montserrat-Bold";
}

div.container-chat div.content-chat div.conversation-container img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 1px solid #cdcdcd;
}


/* RESPONSIVIDADE */
@media screen and (max-width: 800px) {
  div.container-chat {
    padding: 50px 80px;
  }

  div.container-chat div.content-chat div.conversation-container img {
    width: 70px;
    height: 70px;
  }

  nav.nav-logo {
    padding: 15px 80px;
  }
}

@media screen and (max-width: 500px) {
  nav.nav-logo {
    padding: 15px 30px;
  }

  div.container-chat {
    padding: 30px 30px;
  }

  div.type-amypetz,
  div.type-user {
    flex-direction: column;
  }
}

@media screen and (max-width: 350px) {
  nav.nav-logo {
    padding: 15px 10px;
  }

  div.type-amypetz {
    margin-top: 30px;
  }

  div.container-chat {
    padding: 10px 10px;
  }

  div.type-amypetz,
  div.type-user {
    flex-direction: column;
  }
  div.message {
    width: 100% !important;
  }
}