
div.type-amypetz,
div.type-user {
    display: flex;
    gap: 0.8rem;
}

div.type-amypetz img {
    width: 20px;
    height: 80px;
}

div.message  {
    display: flex;
    width: auto;
    height: auto;
    min-height: 50px;
    padding: 10px;
    border-radius: 10px;
    margin-top: 10px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    line-height: 1.5rem;
    align-items: center;
    clear: both;
}

div.type-amypetz {
    align-self: flex-start;
    margin-top: 10px;
}

div.type-user {
    align-self: flex-end;
}

div.type-amypetz div.message {
    display: flex;
    background: #F8F8F8;
    flex-direction: column;
    gap: 0.2rem;
}

div.type-user div.message {
    background: var(--purple-color);
    color: #FFF;
}

button.button-message-box {
    width: 100%;
    height: 50px;
    border: none;
    font-family: "Montserrat-Regular";
    cursor: pointer;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    border-radius: 4px;
}

button.button-message-box:hover {
    background-color: var(--purple-color);
    color: white;
}


select {
    width: 100%;
    height: 30px;
    border: 1px solid #CDCDCD;
    border-radius: 8px;
}

select.select-pet-type:focus,
select.select-pet-breed:focus,
select.select-pet-gender:focus {
    outline: none;
}


div.button-whatsapp-chat a {
    color: var(--purple-color);
    font-weight: 800;
    cursor: pointer;
}

@media screen and (max-width: 500px) {
    div.about-and-message span.name {
        font-size: 0.8rem;
    }

    div.type-amypetz {
        margin-top: 25px;
    }
}